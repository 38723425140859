.form-control {
  border-radius: 0;
}

.btn-go {
  @include button-variant(transparent, transparent);

  font-size: $spacer;
  padding-right: $spacer + 5px;
  text-transform: uppercase;
  text-decoration: none;

  // Bootstrap overrides if it isn't important
  background: url("../img/arrow_right.png") no-repeat right center !important;
  box-shadow: none !important;

  &:hover {
    text-decoration: underline;
  }
}

.clickable {
  cursor: pointer;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;

  background: url("../img/select.png") no-repeat right center;

  &::-ms-expand {
    display: none;
  }
}

button, input[type=button] {
  white-space: normal !important;
}

input:not([type=button]):not([type=submit]) {
  font-size: 16px;
}

.captcha-wrapper {
  overflow: hidden;
  border-radius: 3px;

  .g-recaptcha {
    transform-origin: right;
    transform: scaleX(0.98);

    @include media-breakpoint-down(sm) {
      transform: none;
    }
  }

  .g-recaptcha > div, .g-recaptcha iframe {
    width: 100% !important;
  }
}

.col-form-label {
  // IE does drugs and decides the element does not exist if we omit this.
  padding-right: 0.01px !important;
}


.btn-go-bg {
  background-color:#383d46 !important;
  border-radius: none !important;
  padding-left: 20px;
  padding-right: 30px;
}

.btn-go-top {
  color: #fff;
  box-shadow: none !important;
  background-color:#383d46 !important;
  border-radius: none !important;
  position: relative;
  bottom: 20px;
  left: 20px;
  padding-top: 16px;
  padding-bottom: 10px;

  img {
    margin-right: 10px;
    margin-bottom: 3px;
  }
}

@media (min-width: 992px) {
  .btn-go-top {
    min-width: 260px;
    bottom: 50px;
    left: 212px;
  }
 }
