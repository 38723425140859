.Footer {
  background: linear-gradient(#87898b, #9ea0a2);
  border-top: 1px solid #c2c2c2;
}

.footer-impressum {
  margin: 0 auto;
  position: absolute;
  left: 30%;
}

.iframe {
  border: 0;

}

.modal-data-privacy {
  a {
  color: #000;
  }
}
