.layered {
  position: relative;
  z-index: 0;
}

.layered > :not(:last-child) {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @include media-breakpoint-up(lg) {
    display: block;
  }

  @include media-breakpoint-down(lg) {
    display: none;
  }
}
