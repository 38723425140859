@import "../../theme/index";


@keyframes InlineWindow-clip {
  0% {
    clip-path: polygon(
      0% 0%,
      0% 100%,
      0% 100%,
      0% 0%
    );
  }

  100% {
    clip-path: polygon(
        0% 0%,
        0% 100%,
        100% 100%,
        100% 0%
    );
  }
}


@keyframes InlineWindow-opacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.InlineWindow {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  z-index: 1;

  background: $dark;

  $max-width: calc(100vw - #{map-get($spacers, 3) * 2});
  max-width: $max-width;

  @include media-breakpoint-down(sm) {
    width: $max-width;
  }

  animation: InlineWindow-clip 0.25s linear;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;

    animation: InlineWindow-opacity 0.25s linear;
  }

  .InlineWindow-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    position: sticky;
    top: 0;
    z-index: 1;

    &::before, &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;

      background: $dark;
    }

    &::after {
      background: transparentize(black, 0.75);
    }

    button {
      padding: 1em;

      $anti-padding: - map-get($spacers, 3);

      margin: $anti-padding $anti-padding $anti-padding 0;
    }
  }

  .InlineWindow-body {
    background: $light;
    color: $body-color;
  }

  .InlineWindow-title, .InlineWindow-body {
    padding: map-get($spacers, 3);
  }
}
