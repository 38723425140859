@import "../theme/index";

.bg-gradient {
  background: linear-gradient(#d3d2ce, #faf9f6);
  border-top: 2px groove white;
}

.bg-none {
  background: none;
}

.bg-shade {
  background: transparentize(black, 0.75);
}

.aspect-square {
  padding-bottom: 100%;
}

.help-box {
  border: 1px solid transparentize($light, 0.5);
  padding: map-get($spacers, 2);
}
