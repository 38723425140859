html {
  font-size: 14px;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6,
label,
input[type=button], input[type=submit],
button {
  text-transform: uppercase;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: normal;
}

.underline {
  text-decoration: underline;
}

p {
  margin: 1rem 0;
  font-size: 0.95rem;
}
