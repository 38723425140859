@import "../../theme/index";

.Spinner {
  width: 30px;
  height: 30px;

  border-radius: 50%;
  border: 3px solid transparent;
}

.Spinner.light {
  border-top-color: $light;
}

.Spinner.dark {
  border-top-color: $dark;
}

.Spinner.spinning {
  animation: spin 1s infinite linear;
}

.Spinner:not(.spinning) {
  display: none;
}

@keyframes spin {
  from { transform: scale(1) rotate(0deg); }
  to { transform: scale(1) rotate(360deg); }
}
