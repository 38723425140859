@import "../../theme/index";

.BlockingSpinner {
  display: none;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;

  background: transparentize($dark, 0.3);

  &.loading {
    display: flex;

    align-items: center;
    justify-content: center;
  }
}

.BlockingSpinner.loading + .BlockingSpinner-blur {
  filter: blur(2px);
}
