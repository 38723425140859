@import "../../theme/index";

.TraderSearch .expander {
  background: url("../../img/arrow_down.png") right center no-repeat;
}

.TraderSearch .product-row {
  display: flex;
  flex-direction: column;
  position: relative;

  margin: 0 (map-get($spacers, 3));
  padding: 0 map-get($spacers, 3);

  background-clip: padding-box;
}

.bg-shade {
  margin: 0 -20px;
}

.TraderSearch .product-image {
  z-index: 0;
  position: relative;

  background: no-repeat center;
  background-size: contain;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    background: white;
  }

  &::after {
    background: inherit;
  }
}

.ExtraResultsNotice {
  position: relative;
  padding: map-get($spacers, 3);

  text-align: center;
  border-bottom-style: dashed;

  pointer-events: none;
}

.TraderSearch {
  h6 {
    padding: map-get($spacers, 3) 0;
  }

  h4,
  h6 {
    display: inline-block;
    padding-left: $grid-gutter-width / 2;

    white-space: nowrap;
  }

  .product-buttons {
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: flex-start;
    // margin-left: auto;
    top: 2px;

    .btn {
      padding: 1em;
    }

    img {
      width: 20px;
      height: 20px;
    }
  }
}

.DemonstrationDeviceSelection {
  $padding: map-get($spacers, 3);
  $base-width: 12em;
  $item-width: calc(#{$base-width} + #{$padding * 2});

  .DemonstrationDeviceSelection-body {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: $item-width $item-width $item-width $item-width;
    // grid-template-columns: repeat(4, $item-width);
    justify-content: left;

    @include media-breakpoint-down(sm) {
      grid-template-columns: repeat(auto-fit, $item-width);
    }

    // IE11 stacking bug fix https://stackoverflow.com/q/46743722/1045510
    @include ie-only {
      display: block;
      max-width: calc(#{$base-width * 4} + #{$padding * 4 * 2});
    }
  }

  .DemonstrationDeviceSelection-item {
    position: relative;

    width: $item-width;
    z-index: 0;
    padding: $padding;

    background: none;
    border: none;
    outline: 1px solid transparent;
    cursor: pointer;

    transition: outline-color 0.25s;

    &:hover,
    &:focus {
      outline: 1px solid transparentize($dark, 0.67);
    }

    &.active {
      outline: 1px solid $dark;
    }

    aside {
      text-transform: none;
    }
  }
}

.btn-search {
  color: #fff;
  background-color: #4a505b;
  height: 37px;
}

.product-buttons-point:hover {
  cursor: pointer;
}

.btn-close {
  position: relative;
  z-index: 9999;
  float: right;
  left: 10px;
  bottom: 7px;
}

.close-right {
  position: absolute;
  right: 5px;
}
