@import "../../theme/index";

.RetailerListItem {
  position: relative;
  border-bottom: 1px solid #b4b4b4;
  max-width: calc(100vw - #{2 * map-get($spacers, 3)});

  &:hover, &.selected {
    background: $dark;
    color: $light;
  }
}

.RetailerListItem .information {
  display: flex;
}

.RetailerListItem.detailed .information {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.RetailerListItem .photo {
  $photo-size: 90px;

  height: $photo-size;
  width: $photo-size;
  background: transparent center no-repeat;
  background-size: cover;
}

.RetailerListItem .actions {
  $action-button-size: 30px;

  align-items: flex-end;

  a {
    display: block;
    width: $action-button-size;
    height: $action-button-size;
    color: transparent;

    user-select: none;
  }

  .website {
    background: url("../../img/icon_world.png");
  }

  .email {
    background: url("../../img/icon_mail.png");
  }

  .route {
    background: url("../../img/icon_route.png");
  }
}

.RetailerListItem .details {
  width: 100%;
  cursor: default;

  @include media-breakpoint-up(lg) {
    position: absolute;
    left: 100%;
    max-width: 480px;
  }
}
