@import "../../theme/index";

.DemonstrationDeviceList {
  $margin: map-get($spacers, 3);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(8em, 1fr));
  grid-gap: $margin;

  @include ie-only {
    display: flex;
    flex-wrap: wrap;

    > * {
      width: 8em;
    }

    > :not(:last-child) {
      margin-right: $margin;
      margin-bottom: $margin;
    }
  }
}

.DemonstrationDeviceList-item {
  display: flex;
  flex-direction: column;

  .DemonstrationDeviceList-image {
    width: 100%;
    padding-bottom: 100%;

    background: $light center;
    background-size: contain;
  }

  h6 {
    margin: map-get($spacers, 1) 0 0 0;
  }
}
