@import "../../theme/index";

.cookie-btn-hidden {
  display: none;
}

.cookie-btn {
  background-color: #fff;
  margin: 5px;
}

.cc-container {
  background: #666d7b !important;
}


.cookie-modal-overlay {
  top: 0;
  left: 0;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  z-index: 998;
}

.cookie-modal {
  position: absolute;
  padding: 25px;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 60vw;
  max-height: 80vh;
  overflow-y: auto;
  background-color: white;
  border-radius: 3px;
  z-index: 1000;

  @include media-breakpoint-down(sm) {
    max-width: 100vw;
    max-height: 100vh;
    overflow: scroll;
  }

}



.cookie-text {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.cookie-item {
  margin: 35px 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 0;
}

.cookie-banner {
  position: absolute;
  padding: 25px;
  margin: auto;
  bottom: 0;
  width: 100vw;
  min-height: 100px;
  max-height: 150px;
  background-color: white;
  border-radius: 3px;
  z-index: 1000;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    max-width: 100vw;
    max-height: 100vh;
    height: 100vh;
    overflow: scroll;
  }
}

.outline-btn {
  background: white;
  border: 1px solid #383d46;
  appearance: none;
}

.accept-btn {
  background: #383d46;
  appearance: none;
}

.overflow-body {
  overflow: hidden;
}