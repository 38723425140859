.App {
  min-height: 100vh;
}

.App, .App > main {
  display: flex;
  flex-direction: column;
}

.App > main, .App > main > :last-child {
  flex: 1;
}
