@import "../../theme/index";

$thumb-size: 30px;
$track-thickness: 4px;


.Slider {
  position: relative;
  display: flex;
  align-items: center;
  min-height: $thumb-size;

  margin-left: $thumb-size / 2;
  margin-right: $thumb-size / 2;

  .track {
    width: 100%;
    border: ($track-thickness / 2) solid white;
  }

  .thumb {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    width: $thumb-size;
    height: $thumb-size;
    top: 0;

    background: #c2c2c2;
    border: 1px solid white;
    border-radius: 100%;
    margin-left: -$thumb-size / 2;
    outline: none;

    transition: left 100ms;
  }
}

@include media-breakpoint-down(sm) {
  .Slider .thumb.active {
    transform: scale(1.3);
  }
}
