@import "../../theme/index";

$active-background: transparentize(black, 0.75);

.TabArea {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  margin: 0 (- map-get($spacers, 3));
}

.Tab-title {
  flex: 1;

  border: none;
  padding: (map-get($spacers, 3) / 2) map-get($spacers, 3);
  font-size: 1.3rem;
  background: none;
  cursor: pointer;

  color: $light;
  text-align: left;

  &.active,
  &:focus {
    background: $active-background;
    outline: none;
  }
}

.Tab-body {
  order: 1;
  max-width: calc(100vw - #{2 * map-get($spacers, 3)});
  width: 100%;
  padding: map-get($spacers, 3);
  background: $active-background;
  max-height: 400px;
  overflow-y: auto;
}