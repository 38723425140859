// Spacings
$spacer: 20px;
$form-group-margin-bottom: ($spacer / 2);
$form-check-input-margin-y: 0.15rem;

// Grid
$grid-breakpoints: (
  xs: 0,
  sm: 480px,
  lg: 1024px,
);

$container-max-widths: (
  xs: 99.999%, // Bootstrap requires an ascending map
  sm: 100%,
  lg: 1024px,
);

// Colors
$dark: #4a505b;

$link-color: white;
$link-decoration: underline;
