.no-gutters > .row {
  margin-left: 0;
  margin-right: 0;

  > .col,
  > [class*="col-"] {
    &:not(input) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
